<template>
  <div id="box">
    <app-header />
    <!-- <transition name="el-fade-in"> -->
      <router-view style="flex: auto" />
    <!-- </transition> -->
    <app-footer />
  </div>
</template>

<script>
import Header from "@/layout/Header";
import Footer from "@/layout/Footer";
export default {
  components: {
    [Header.name]: Header,
    [Footer.name]: Footer,
  },
  data() {
    return {};
  },

  created() {
    console.log(process.env);
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
</style>