import request from '@/utils/request'

//首页商品列表
export async function CommList(data) {
  return await request({
    url: '/business-server/HomePage/CommList',
    method: 'post',
    data
  })
}
//首页单品编码商品列表
export async function CommodityCodeList(data) {
  return await request({
    url: '/business-server/HomePage/CommodityCodeList',
    method: 'post',
    data
  })
}

//首页品牌列表
export async function QueryBrandList(data) {
  return await request({
    url: '/business-server/HomePage/queryBrandList',
    method: 'post',
    data
  })
}

//pc输入框模糊查询
export async function inputSearch(data) {
  return await request({
    url: '/business-server/HomePage/queryCommList',
    method: 'post',
    data
  })
}

//首页店铺列表
export async function storeLIst(data) {
  return await request({
    url: '/business-server/HomePage/StoreList',
    method: 'post',
    data
  })
}

//推荐商品 企业首页
export async function enterprise(data) {
  return await request({
    url: '/business-server/HomePage/EnterpriseHomePage',
    method: 'post',
    data
  })
}

//首页轮播图
export async function getBanner(data) {
  return await request({
    url: '/business-server/HomePage/slideshow',
    method: 'post',
    data
  })
}
//首页活动
export async function getActivityList(data) {
  return await request({
    url: '/business-server/Activity/homeActivityList',
    method: 'post',
    data
  })
}

//全部分类接口
export async function getAllClass(data) {
  return await request({
    url: '/business-server/Classify/queryCategoriesCatalogApi',
    method: 'post',
    data
  })
}
// export const getCommList = (data) => axios.post("/business-server/HomePage/CommList", data);