import axios from 'axios'
import Cookies from "js-cookie";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_USER_API, // url = base url + request url 
  // baseURL: 'http://192.168.1.156:20003', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

service.interceptors.request.use(config => {
  config.headers['Region'] = "FJ"
  // var token = "0897E30F-EEB9-4C3D-A404-6A0EACA97858"
  const token = Cookies.get("token_personal");

  token && (config.headers.common['Authorization'] = `Bearer ${token}`)

  return config
},
  err => {
    console.log(err)
    return Promise.reject(err)
  });

export default service
