import axios from 'axios'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url 
  // baseURL: 'http://192.168.1.156:20003', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

service.interceptors.request.use(config => {

  // config.headers['Region'] = "SG"
  config.headers['Region'] = "FJ"

  return config
},
  err => {
    console.log(err)
    return Promise.reject(err)
  });

export default service
