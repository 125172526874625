import { getUser, getShoppingCartSum } from "@/api/PersonalUser"

export default {
    namespaced: true,
    state: {
        userId: "", //消费者id
        userInfo: { userEntity: { name: null }, userDetailEntity: { profilePicture: null } }, //消费者信息
        userLogin: false,  //消费者登录
        userSum: 0 // 商品数量
    },
    mutations: {
        setUserId(state, payload) {
            state.userId = payload
            state.userLogin = true
        },
        //用户信息
        setUser(state, payload) {
            state.userInfo = payload
        },
        getCartSum(state, payload) {
            state.userSum = payload
        },
    },
    actions: {

    }

}
