import Vue from 'vue'
import VueRouter from 'vue-router'
import root from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'root',
    component: root,
    redirect: '/Home',
    children: [
      {
        path: 'Home',
        name: 'home',
        meta: { name: '首页' },
        component: () => import(/* webpackChunkName:'home' */'@/views/Home'),
      }, {
        path: 'Store', //店铺页面
        name: 'store',
        redirect: '/Store/HomePage/:id',
        component: () => import(/* webpackChunkName:'store' */'@/views/Store'),
        children: [
          {
            path: 'HomePage/:id',
            name: 'home-page',
            meta: { name: '店铺首页' },
            component: () => import(/* webpackChunkName:'HomePage' */'@/views/Store/HomePage'),
          },
          {
            path: 'HomePage/:id/GoodsDetails/:barcode',
            name: 'goodsd-etails',
            meta: { name: '商品详情' },
            component: () => import(/* webpackChunkName:'GoodsDetails' */'@/views/Store/GoodsDetails'),
          },
          {
            path: 'HomePage/:id/StoreActivity/:activityId',
            name: 'store-activity',
            meta: { name: '店铺活动' },
            component: () => import(/* webpackChunkName:'StoreActivity' */'@/views/Store/StoreActivity'),
          }, {
            path: 'HomePage/:id/StoreCategory',
            name: 'store-category',
            meta: { name: '店铺类目' },
            component: () => import(/* webpackChunkName:'StoreCategory' */'@/views/Store/StoreCategory'),
          },
        ]
      }, {
        path: 'AllCtegories', // 类目页面
        name: 'all-ctegories',
        redirect: '/AllCtegories',
        component: () => import(/* webpackChunkName:'category' */'@/views/Category'),
        children: [
          {
            path: '',
            meta: { name: '全部类目' },
            component: () => import(/* webpackChunkName:'CategoryList' */'@/views/Category/AllCategories'),
          }, {
            path: 'CategoryList',
            name: 'category-list',
            component: () => import(/* webpackChunkName:'CategoryList' */'@/views/Category/CategoryList'),
          }
        ]
      }, {
        path: 'AllBrand', //品牌
        name: 'all-brand',
        redirect: '/AllBrand',
        component: () => import(/* webpackChunkName:'Brand' */'@/views/Brand'),
        children: [{
          path: '',
          meta: { name: '全部品牌' },
          component: () => import(/* webpackChunkName:'Brand' */'@/views/Brand/AllBrand'),
        },
        {
          path: 'RecommendedBrand/:id/:brandId',
          name: 'recommended-brand',
          meta: { name: '推荐品牌' },
          component: () => import(/* webpackChunkName:'RecommendedBrand' */'@/views/Brand/RecommendedBrand'),
        },
        {
          path: 'BrandGoods/:brandId',
          name: 'brand-goods',
          component: () => import(/* webpackChunkName:'RecommendedBrand' */'@/views/Brand/BrandGoods'),
        }
        ]
      }, {
        path: 'TrackBack',
        meta: ["轨迹溯源"],
        name: 'track-back',
        component: () => import(/* webpackChunkName:'TrackBack' */'@/views/TrackBack'),
      }, {
        path: 'AllActivities',
        meta: ["全部活动"],
        name: 'all-activities',
        component: () => import(/* webpackChunkName:'AllActivities' */'@/views/Activity'),
      }, {
        path: 'ActivitiesColumn/:id',
        name: 'activities-column',
        component: () => import(/* webpackChunkName:'ActivitiesColumn' */'@/views/Activity/ActivitiesColumn'),
      }, {
        path: 'StoreList/:id?',
        name: 'store-list',
        component: () => import(/* webpackChunkName:'StoreList' */'@/views/StoreList'),
      },
      {
        path: 'AllGoods',
        name: 'all-goods',
        component: () => import(/* webpackChunkName:'AllGoods' */'@/views/AllGoods')
      }, {
        //新闻
        path: 'News',
        name: 'news',
        redirect: 'News/NewsList',

        component: () => import(/* webpackChunkName:'News' */'@/views/News'),
        children: [
          {
            path: 'NewsList',
            name: 'news-list',
            component: () => import(/* webpackChunkName:'NewsList' */'@/views/News/NewsList'),
          },
          {
            path: 'NewsDetails/:newsCode',
            name: 'news-details',
            component: () => import(/* webpackChunkName:'NewsDetails' */'@/views/News/NewsDetails'),
          }]
      }, {
        //c端个人中心
        path: 'PersonalCenter',
        name: 'personal-center',
        component: () => import(/* webpackChunkName:'PersonalCenter' */'@/views/PersonalCenter'),
        redirect: 'PersonalCenter/AllOrders',
        children: [
          {
            //全部订单
            path: 'AllOrders',
            name: 'all-orders',
            component: () => import(/* webpackChunkName:'AllOrders' */'@/views/PersonalCenter/AllOrders'),
          }, {
            //待付款订单
            path: 'OrderPendingPayment',
            name: 'order-pending-payment',
            component: () => import(/* webpackChunkName:'OrderPendingPayment' */'@/views/PersonalCenter/AllOrders'),
          }, {
            //待发货订单
            path: 'BacklogOrder',
            name: 'backlog-order',
            component: () => import(/* webpackChunkName:'AllOrders' */'@/views/PersonalCenter/AllOrders'),
          }, {
            //待收货订单
            path: 'OrderToBeReceived',
            name: 'order-to-be-received',
            component: () => import(/* webpackChunkName:'OrderToBeReceived' */'@/views/PersonalCenter/AllOrders'),
          }, {
            //用户资料
            path: 'UserData',
            name: 'user-data',
            redirect: 'UserData/DataPage',
            component: () => import(/* webpackChunkName:'UserData' */'@/views/PersonalCenter/UserData/index'),
            children: [{
              path: 'DataPage',
              name: 'data-page',
              component: () => import(/* webpackChunkName:'DataPage' */'@/views/PersonalCenter/UserData/DataPage'),
            }, {
              path: 'PhoneNumber',
              name: 'phone-number',
              component: () => import(/* webpackChunkName:'PhoneNumber' */'@/views/PersonalCenter/UserData/PhoneNumber'),
            }, {
              path: 'ChangePassword',
              name: 'change-password',
              component: () => import(/* webpackChunkName:'ChangePassword' */'@/views/PersonalCenter/UserData/ChangePassword'),
            }]
          }, {
            //收货地址
            path: 'ShippingAddress',
            name: 'shipping-address',
            component: () => import(/* webpackChunkName:'ShippingAddress' */'@/views/PersonalCenter/ShippingAddress'),
          },
          {
            //订单详情
            path: 'OrderDetails',
            name: 'order-details',
            component: () => import(/* webpackChunkName:'OrderDetails' */'@/views/PersonalCenter/OrderDetails/index'),
          },
        ]
      }, {
        //购物车
        path: 'ShoppingCart',
        name: 'shopping-cart',
        component: () => import(/* webpackChunkName:'ShoppingCart' */'@/views/ShoppingCart'),
      }, {
        //订单
        path: 'ConfirmAnOrder',
        name: 'confirm-an-rder',
        component: () => import(/* webpackChunkName:'ConfirmAnOrder' */'@/views/ConfirmAnOrder'),
      },
      {
        //支付
        path: 'Pay',
        name: 'pay',
        component: () => import(/* webpackChunkName:'Pay' */'@/views/Pay'),
        redirect: 'Pay/ModeOfPayment',
        children: [
          {
            path: 'ModeOfPayment',
            name: 'mode-of-payment',
            component: () => import(/* webpackChunkName:'ModeOfPayment' */'@/views/Pay/ModeOfPayment'),
          },
          {
            path: 'Cashier',
            name: 'cashier',
            component: () => import(/* webpackChunkName:'Cashier' */'@/views/Pay/Cashier'),
          },
          {
            path: 'CompletionOfPayment',
            name: 'completion-of-payment',
            component: () => import(/* webpackChunkName:'CompletionOfPayment' */'@/views/Pay/CompletionOfPayment'),
          },

        ]
      },
      {
        path: 'Logistics',
        name: 'logistics',
        component: () => import(/* webpackChunkName:'Logistics' */'@/views/Logistics'),
      }, {
        path: 'BankingServices',
        name: 'banking-services',
        component: () => import(/* webpackChunkName:'BankingServices' */'@/views/BankingServices'),
      }, {
        path: 'InsuranceServices',
        name: 'insurance-services',
        component: () => import(/* webpackChunkName:'InsuranceServices' */'@/views/InsuranceServices'),
      }, {
        //数字权益
        path: 'DigitalRights',
        name: 'digital-tights',
        component: () => import(/* webpackChunkName:'DigitalRights' */'@/views/DigitalRights'),
        children: [{
          path: '',
          name: 'mall',
          component: () => import(/* webpackChunkName:'Mall' */'@/views/DigitalRights/Mall'),
        }, {
          path: 'MallDetail',
          name: 'mall-detail',
          component: () => import(/* webpackChunkName:'Mall' */'@/views/DigitalRights/MallDetail'),
        },]
      }, {
        path: 'Test',
        name: 'test',
        component: () => import(/* webpackChunkName:'Test' */'@/views/Test'),
      }
    ]
  }
]


import Cookies from "js-cookie";

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
router.beforeEach((to, from, next) => {

  // if (Cookies.get("token_personal") || Cookies.get("KAI_HUA_ADMIN_TOKEN") != undefined) {

  // } else {
  //   window.location.href = process.env.VUE_APP_URL_API
  // }

  // 路由跳转后，让页面回到顶部
  // chrome
  // // console.log(document.body.scrollTop);
  // console.log(document.body.children);
  document.body.scrollTop = 0; // firefox
  document.documentElement.scrollTop = 0; // safari
  window.pageYOffset = 0;


  next();

})
export default router
